import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Helpful Concepts`}</h1>
    <ul>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/complexity",
          "title": "complexity"
        }}>{`complexity`}</a>{``}</li>
      <li parentName="ul">{`complex adaptive systems`}</li>
      <li parentName="ul">{`cybernetics`}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/computational-sociology",
          "title": "computational sociology"
        }}>{`computational sociology`}</a>{``}</li>
      <li parentName="ul">{`qualitative vs quantitative, see `}<a parentName="li" {...{
          "href": "/Wikisurvey",
          "title": "💡 Wikisurvey"
        }}>{`💡 Wikisurvey`}</a>{``}</li>
      <li parentName="ul">{`emergence`}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/collective-intelligence",
          "title": "collective intelligence"
        }}>{`collective intelligence`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/facilitation",
          "title": "facilitation"
        }}>{`facilitation`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/dimensionality-reduction",
          "title": "dimensionality reduction"
        }}>{`dimensionality reduction`}</a>{``}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      